.wrapper
    display: flex
    flex-direction: row
    justify-content: space-evenly
    .section
        width: 45%
        display: flex
        flex-direction: column
        padding: 30px
        border-radius: 20px
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)
        height: fit-content
        h4
            font-size: 32px
            margin-bottom: 20px
        h6
            font-size: 24px
            margin-bottom: 10px
    .leftSection
        background-color: #1165b4
        color: #fff
    .rightSection
        background-color: #5b42f3
        color: #fff
    button
        padding: 20px 30px
        border-radius: 40px
        border: none
        color: #fff
        font-weight: 700
        font-size: 18px
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        max-width: 250px
    .cancelSubscriptionBtn
        background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)
        opacity: 100%
        margin-top: 20px
        border: 0px solid transparent
        &:hover
            transition: all 1s ease-in-out
            border: 1px solid #fff
    .logoutBtn
        background-image: linear-gradient(144deg, #ff4040, #f39242 50%, #eb2f00)
        opacity: 100%
        margin-top: 20px
        border: 0px solid transparent
        &:hover
            transition: all 1s ease-in-out
            border: 1px solid #fff