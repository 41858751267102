// CheckboxWithConfetti.module.sass

.checkboxContainer
    display: flex
    width: fit-content
    position: relative
    cursor: pointer
    width: 100%
    align-items: center

.customCheckbox
    width: 40px
    height: 40px
    cursor: pointer
    max-height: 40px
    max-width: 40px
    display: flex
    justify-content: center
    align-items: center

.checkboxLabel
    color: #000
    font-weight: 600
    font-size: 16px
    margin-left: 10px
    flex: 1