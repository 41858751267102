.bgRemoverWrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
.headerSection
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin-bottom: 30px
    color: #111827
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7)
    width: 100%
    h1
        font-size: 42px
        max-width: 75%
        margin-bottom: 20px
    sub
        font-size: 24px
        max-width: 50%
        text-align: center
        line-height: 28px
        margin-bottom: 20px
.dropzoneWrapper
    width: 70%
.allPreviewActions
    display: flex
    width: 100%
    align-items: center
    justify-content: center
    margin: 20px 0px
.filesPreview
    display: flex
    flex-direction: column
    margin-top: 30px
    align-items: center
    width: 70%
    .noFilesText
        font-size: 24px
        color: #111827
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7)
        display: flex
        justify-content: center
        flex-direction: column
        button
            margin-top: 20px
    .filePreviewWrapper
        display: flex
        flex-direction: row
        align-items: center
        padding: 10px
        border-radius: 10px
        margin-bottom: 10px
        width: 100%
        -webkit-box-shadow: -1px 1px 4px 4px rgba(0,0,0,0.3)
        -moz-box-shadow: -1px 1px 4px 4px rgba(0,0,0,0.3)
        box-shadow: -1px 1px 4px 4px rgba(0,0,0,0.3)
        border: 1px solid rgba(216, 216, 216, 0.8)
        img
            border-radius: 5px
        p
            font-size: 20px
            margin-left: 20px
        .previewInfo
            display: flex
            flex-direction: column
            justify-content: center
            align-items: start
    .actionsContainer
        margin-left: auto
        display: flex
        flex-direction: row
.bgRemoverWrapper
    color: #111827
    button
        padding: 20px 30px
        border-radius: 40px
        border: none
        color: #fff
        font-weight: 700
        font-size: 18px
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
    .startBtn
        background-color: #19E3AB
        opacity: 100%
    .openBtn
        background-color: #19E3AB
        opacity: 100%
        margin-right: 10px
    .downloadBtn
        background-color: #1943E3
        opacity: 100%
    .removeBtn
        background-color: #D6385C
        opacity: 100%
        margin-right: 20px