.header
    position: fixed
    top: 0px
    left: 0px
    width: 100vw
    max-width: 100vw
    height: 80px
    // background-color: rgba(25, 67, 227, 0.8)
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)
    z-index: 2
    padding: 10px 40px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    box-shadow: 0px 20px 20px -10px rgba(17, 17, 17, 0.5)
    box-sizing: border-box
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px
    .siteName
        font-family: 'Lilita One', serif
        font-size: 26px
        font-weight: 400
        color: #fff
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7)
        text-decoration: none !important
        display: flex
        flex-direction: row
        align-items: center
    .logo
        height: 50px
        width: 50px
        margin-right: 20px
    .rightLinks
        display: flex
        align-items: center
        a
            font-family: 'PT Serif', serif
            color: #fff
            font-size: 20px
            text-decoration: none
            text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7)
            &:hover
                transition: all 0.5s ease-in-out
                color: #cccccc
        .navLink
            margin-right: 20px
            font-family: Phantomsans, sans-serif
    .loginBtn
        background-color: transparent
        padding: 10px 
        border-radius: 50px
        font-size: 18px
        color: #fff
        cursor: pointer
        background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)
        border: 1px solid #00ddeb
        &:hover
            transition: all 0.2s ease-in-out
            border: 1px solid #fff
.avatarNotSignedIn
    box-sizing: border-box
    position: relative
    border-radius: 50%
    box-shadow: inset 0 0 0 2px transparent
    width: 50px
    height: 50px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    .avatarImage
        max-width: 100%
        vertical-align: middle
        border-radius: 50%
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.2)
    .avatarFrame
        position: absolute
        pointer-events: none
        z-index: 10
        width: 100%
        height: 100%
        top: 0,
        left: 0
    .animSpin
        animation: spin 20s linear infinite
    &:hover
        transition: all 0.5s ease-in-out
        .avatarImage
            fill: #000

@keyframes spin 
    0%
        transform: rotate(0deg)
    100% 
        transform: rotate(360deg)