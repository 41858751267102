.glowOnHover
    width: 200px
    height: 40px
    border: none
    outline: none
    color: #fff
    background: #212121
    cursor: pointer
    position: relative
    z-index: 0
    border-radius: 10px
    font-size: 14px
    font-weight: 600
    font-family: 'Montserrat', sans-serif
    display: flex
    align-items: center
    justify-content: center
    white-space: nowrap
    &:before
        content: ""
        background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)
        position: absolute
        top: -2px
        left: -2px
        background-size: 300%
        z-index: -1
        filter: blur(5px)
        width: calc(100% + 4px)
        height: calc(100% + 4px)
        animation: glowing 15s linear infinite
        opacity: 0
        transition: opacity 0.3s ease-in-out
        border-radius: 10px
    &:active
        color: #000
    &:active:after
        background: transparent
    &:hover:before
        opacity: 1
    &:after
        z-index: -1
        content: ""
        position: absolute
        width: 100%
        height: 100%
        background: #212121
        background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)
        left: 0
        top: 0
        border-radius: 10px

.glowLoading
    animation: glowing 15s linear infinite !important

@keyframes glowing
    0%
        background-position: 0 0

    50%
        background-position: 300% 0

    100%
        background-position: 0 0
