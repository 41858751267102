.route
    display: flex
    justify-content: center
    align-items: center
    width: 100%
.wrapper
    display: flex
    justify-content: center
    align-items: start
    flex-direction: column
    max-width: 1000px
    hr
        width: 65%
        height: 30px
        border-style: solid
        border-color: rgba(191, 191, 191, 0.9)
        border-width: 1px 0 0 0
        border-radius: 15px
        &::before
            content: ""
            display: block
            height: 30px
            margin-top: -31px
            border-style: solid
            border-color: rgba(191, 191, 191, 0.9)
            border-width: 0 0 1px 0
            border-radius: 15px
        &::after
            content: ""
    h1
        font-size: 48px
        text-align: center
        margin: 0px auto 10px auto
    h2
        font-size: 24px
        text-align: center
        margin-bottom: 40px
    hr
        height: 1px
        background-color: #000
    h3
        font-size: 28px
        margin: 30px 0px 10px 0px
    p
        font-size: 18px
        line-height: 22px
